export default {
    navigation: {
        dev: 'Карты',
        referrals: 'Друзья',
        home: 'База',
        bonus: 'Бонус',
        airDrop: 'AirDrop'
    },

    cards: {
        emptyList: 'В этом разделе пока нет карт для покупки...',
        profitHour: 'Прибыль в час',

        chargeOn: 'С зарядом',
        charge: 'Зарядить',
        payCard: 'Купить',
        payCardOk: 'Успешно!',
        payChargeOk: 'Заряд активирован',

        infoActiveCharge: 'Заряд активен',
        openPartnerLink: 'Перейти'
    },

    home: {
        tapProfit: 'Тап-профит',
        levelUp: 'След. lvl',
        profitOnHour: 'Доход/ч.',
        level: 'Уровень',
        dragonPower: 'Мощность',
        boosts: 'Бусты'
    },

    referrals: {
        inviteBlockTitle: 'Приглашайте друзей',
        inviteBlockText: 'И получайте монеты для вашего уровня',
        totalReward: 'Получено:',
        myFriends: 'Мои друзья',
        inviteButton: 'Пригласить друзей',

        modalTitle: 'Награда для вашего уровня',
        modalInfo: 'за каждого друга',

        inveteMessageText: 'Присоединяйся и прокачай своего дракона!',
        notifiCopiedLink: 'Ссылка скопирована',

        emptyList: 'У вас пока нет приглашенных друзей'
    },

    bonus: {
        title: 'Задания',
        description: 'Выполняйте задания чтобы получить больше монет',
        everyday: 'Бонусы',
        everyday_reward: 'Ежедневная награда',

        modalTitle: 'Заходите каждый день чтобы получить монеты',
        modalDay: 'День',

        claimReward: 'Получить награду',
        notifiClaimOk: 'Награда получена'
    },

    airDrop: {
        title: 'Выполните все задания чтобы получить AirDrop',
        taskTap: 'Задания',
        withdrawalTap: 'Withdrawal',
        soon: 'Скоро',

        startTask: 'Выполнить',
        repeatTask: 'Повторить',
        checkStatusTask: 'Проверить',
        rewardOk: 'Награда получена',
        rewardOkNotifi: 'Награда получена',
        taskDone: 'Задание выполнено',
        taskNoDone: 'Задание не выполнено',
        openTarget: 'Открыть',

        infoCodeText: 'Введите полученный код из задания чтобы получить награду',
        inputApproveCode: 'Введите код',
        sendCode: 'Получить награду',
        errorCode: 'Неверный код',

        disconnectTon: 'Ton кошелек отключен',
        rewardLeft: 'Награда была получена ранее',
        btnDisconnectTon: 'Откл.',
        beforeConnectTON: 'Сразу подключите TON',

        transactionNot: 'Транзакция не выполнена'
    },

    boosters: {
        title: 'Улучшения',
        tapProfit: 'Прибыль за тап',
        dragonPower: 'Сила дракона',

        currentProfitPerTap: 'Прибыль за тап',
        profitPerTapDescription: 'Увеличивает количество монет которое вы получаете за один тап на 1',

        currentDragonPower: 'Мощность дракона',
        dragonPowerDescription: 'Увеличивает мощность вашего дракона на 500',

        upgradeButton: 'Улучшить',
        upgradeOk: 'Улучшено!'
    },

    other: {
        noCoins: 'Недостаточно монет',
        telegramSupport: 'Поддержка в Telegram',
        telegramSupportOpen: 'Открыть',

        settingVibro: 'Вибро-отклик',
        settingVibroOn: 'Вкл.',
        settingVibroOff: 'Откл.',

        liderBoardEmptyList: 'Пусто :(',

        noTon: 'Недостаточно TON'
    },

    modalGameInfo: {
        title: 'Информация',
        description: "Lorem ipsum odor amet, consectetuer adipiscing elit. Ridiculus odio quisque aptent praesent himenaeos, mus vitae. Lorem ipsum odor amet, consectetuer adipiscing elit. Ridiculus odio quisque aptent praesent himenaeos, mus vitae.",
        button: "В игру"
    },

    payTON: {
        modalTitle: 'Пополнить TON',
        modalInfo: 'Укажите сумму пополнения TON, минимум 1',
        modalPlaceholder: 'Укажите сумму',
        modalBtnBuy: 'Пополнить',
        buyOk: 'Баланс TON пополнен'
    },

    balanceHistory: {
        titlePage: 'Движение средств',
        emptyList: 'Пока пусто...',

        passive_income: 'Пассивный доход',
        tap_profit: 'Доход за тапы',
        task_reward: 'Выполнение задания',
        buy_booster: 'Покупка буста',
        buy_ton: 'Пополнение TON',
        buy_card: 'Апгрейд карты',
        charged_card: 'Заряд карты'
    }
};
